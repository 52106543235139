import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'
import styled from 'styled-components'

import Title from '../general/util/Title'
import HighlightsLoader from '../general/util/HighlightsLoader'
import ArticleListItem from '../general/article/ArticleListItem'
import { errors as err } from '@otavamedia/om-component-library/lib/lib/errors'
import { ErrorPlaceholderBox, withErrorBoundary } from '../general/util/ErrorBoundaries'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'

import './IndexHighlights.scss'
import track from 'react-tracking'
import { breakpoints } from '@otavamedia/om-component-library/lib/lib/utils'
import Breakpoint from '@otavamedia/om-component-library/lib/util/Breakpoint'
import WhyOrder from '../widgets/WhyOrder'
import { indexLayout as layout } from '../../lib/defaults'
import AiWidget from '../general/widgets/AiWidget';

const EditorialPick = styled(ArticleListItem)`
  flex-direction: row;
  flex-wrap: wrap;
  .card-meta {
    order: 1;
    width: 100%;
    padding: 1rem;
  }
  .image-container {
    order: 2;
    padding: 0 1rem;
    width: 40%;
    border-bottom: none;
    max-height: 150px;
    margin-bottom: 1rem;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 auto;
  }
  .card-content {
    order: 3;
    padding: 0 1rem 1rem 0;
    width: 60%;
  }
`

/**
 * This renders the highlights on the front page (large article cards at the top of the page).
 * The layout of the highlights is not hard-coded, instead it comes from defaults.js.
 * This highlights block can contain highlight articles, sticky articles and/or a live video block.
 *
 */
class IndexHighlights extends Component {
  renderContents (highlights, stickies, liveVideo) {
    let highlightIdx = 0
    let stickyIdx = 0
    return layout.highlights.boxes.map((section, idx) => {
      const sectionContent = section.items.map((article, idx2) => {
        const isFirstCard = !idx && !idx2
        // If there is a live video, it is always rendered in the first card slot on the page.
        if (isFirstCard && liveVideo && liveVideo.id) {
          return this.renderLiveVideo(idx2, liveVideo)
        }
        let articleToRender
        if (article.contentType === 'sticky') {
          if (!stickies[stickyIdx]) {
            return <div key={idx2}>[ missing sticky article ]</div> // TODO: should there be a fallback?
          }
          articleToRender = stickies[stickyIdx++]
        } else if (article.contentType === 'highlight') {
          if (!highlights[highlightIdx]) {
            return <div key={idx2}>[ missing highlight article ]</div> // TODO: should there be a fallback?
          }
          articleToRender = highlights[highlightIdx++].item
        } else {
          throw err.unknownContentTypeError({ type: article.contentType })
        }

        let size = article.size
        if (size === 'auto') {
          switch (0) {
          case section.items.length % 3:
            size = 'four-columns'
            break
          case section.items.length % 2:
            size = 'sm'
            break
          default:
            size = 'four-columns'
          }
        }
        const props = {
          key: idx2,
          article: articleToRender,
          size,
          notShown: { relatedArticles: !article.showRelated },
          sticky: article.contentType === 'sticky' && articleToRender.sticky,
          relatedArticleListLimit: article.contentType === 'sticky' ? 1 : 0,
          noImage: article.noImage,
          thumbnail: section.boxSize === 'secondary',
        }

        return section.boxSize === 'secondary'
          ? <EditorialPick
            {...props}
            sizes="(max-width: 768px) 33vw, (max-width: 1440px) 25vw, 320px"
            imageSize={IMAGE_SIZE.MEDIUM_LARGE}
          />
          : <ArticleListItem
            {...props}
            sizes="(max-width: 768px) 100vw, (max-width: 1024px) 75vw, (max-width: 1440px) 50vw, 720px"
            imageSize={IMAGE_SIZE.MEDIUM_LARGE}
          />
      })

      return this.renderSection(section, idx, sectionContent)
    })
  }

  renderLiveVideo (idx2, liveVideo) {
    return <div styleName="bottom-margin">
      <div key={idx2} styleName="live-video-box">
        <div styleName="live-video">
          <iframe title="fbVideo"
            src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebookapp%2Fvideos%2F${liveVideo.id}%2F&show_text=false`}
            style={{ border: 'none', overflow: 'hidden', width: '100%', height: '100%' }} scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
        <div styleName="card-content"><h2><a
          href={`https://www.facebook.com/facebookapp/videos/${liveVideo.id}/`}>{liveVideo.title}</a></h2>
        </div>
      </div>
    </div>
  }

  renderSection (section, idx, sectionContent) {
    switch (section.boxSize) {
    case 'large':
      return <div styleName="large-col" key={idx}>{sectionContent}</div>
    case 'small':
      return <div styleName="small-col" key={idx}>{sectionContent}</div>
    case 'secondary':
      return <Fragment key={idx}>
          <AiWidget/>
        <Breakpoint maxBreakpoint={breakpoints.sm}>
          <div styleName="small-col">
            <WhyOrder/>
          </div>
        </Breakpoint>
        <div styleName="secondary-highlights-container" key={idx}>
          <Title text="Toimituksen valinnat" />
          <div styleName="secondary-highlights">
            <Fragment>
              {sectionContent}
            </Fragment>
          </div>
        </div>
      </Fragment>
    default:
      throw err.unknownSizeError({ size: section.boxSize })
    }
  }

  render () {
    const { highlights, stickies, error, liveVideo } = this.props

    if (error) {
      throw error
    }
    return (
      <ReactPlaceholder ready={!!highlights && !!highlights.length} customPlaceholder={<HighlightsLoader />}>
        {highlights ? this.renderContents(highlights, stickies, liveVideo) : <div/>}
      </ReactPlaceholder>
    )
  }
}

IndexHighlights.propTypes = {
  swap: PropTypes.bool,
  highlights: PropTypes.array,
  stickies: PropTypes.array,
  liveVideo: PropTypes.object,
  error: PropTypes.any,
}

export default track({ gtmContext: ['IndexHighlights'] })(withErrorBoundary(
  IndexHighlights,
  ErrorPlaceholderBox('Artikkeleita ei saatu ladattua'),
))
