import React, { Component } from 'react'
import tuuma from '../../../assets/logos/tuuma3.svg'
import arrow from '../../../assets/ui/ai-arrow.svg'
import arrowHover from '../../../assets/ui/ai-arrow-hover.svg'
import lock from '../../../assets/ui/lock.svg'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'

import { Link } from '../util/Links'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { withRouter } from 'react-router-dom'
import circle from '../../../assets/ui/questionmark.svg'
import circleGray from '../../../assets/ui/questionmark-gray.svg'
import tuuma2 from '../../../assets/logos/tuuma2.png'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { getURL } from '@otavamedia/om-component-library/lib/lib/WPClient'
import { FacebookNoBgLogo, MailNoBgLogo, TwitterNoBgLogo } from '../../widgets/Icons'
import shuffle from 'lodash/shuffle'

import './AiArticleWidget.scss'


/**
 *
 */
@connect({
  props: [
    auth, [
      'accessLevel',
    ],
  ]
})
class AiArticleWidget extends Component {
  constructor (props) {
    super(props)
    this.state = {
      relevant_questions: [],
      requiredUserLevel: 3
    }
    if (props.article || !props.article.tuumaOff) {
      this.state.relevant_questions = shuffle(props.article.relatedArticles.map((article => (article.tuumaAnswer ? {
        answer: article.tuumaAnswer,
        question: article.tuumaQuestion,
        source_documents: [{
          title: article.title,
          url: article.link
        }]
      } : null))).filter(x => x)).slice(0,2)
    }
    this.getPermission()

    window.addEventListener('resize', this.setHeight)
    setTimeout(() => {
      this.setHeight()
    }, 400)
  }
  async getPermission () {
    const res = await WP.aiPermission()
    this.setState({ requiredUserLevel: res ? res.requiredUserLevel : 3 })
  }

  async postQuestion () {
    this.props.history.push('/tuuma?question=' + encodeURIComponent(this.state.searchString))
  }

  handleEnter = event => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.postQuestion()
    }
  }

  setHeight () {
    const textarea = document.getElementById('tuuma-textarea')
    if (textarea) {
      textarea.style.height = 'auto' // Reset the height to auto
      textarea.style.height = `${textarea.scrollHeight}px` // Set the height based on content
    }
    const ask = document.getElementById('ask')
    if (textarea && ask) {
      ask.style.height = 'auto' // Reset the height to auto
      ask.style.height = `${textarea.scrollHeight}px` // Set the height based on content
    }
  }

  textChange (e) {
    if (!e.currentTarget.value.substr(0, 200).endsWith('\n')) {
      this.setState({ searchString: e.currentTarget.value.substr(0, 200) })
      this.setHeight()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setHeight)
  }

  renderAnswer (item) {
    return <div styleName="question-answer">
      <div styleName="question">
        <div>
          <img src={circle} alt="?"/>
        </div>
        <div>{item.question}
        </div>
      </div>
      <div styleName="answer">
        <div>
          <img src={tuuma2} alt="Tuuma"/>
        </div>
        <div styleName={ null}>
          <HTML>{item.answer}</HTML>
          {item.hash
            ? <div styleName="share">
              <Link to={{ link: getURL('/wp-json/om/v1/tuuma/image?id=' + item.hash) }} target="_blank">Jaa
                vastaus <TwitterNoBgLogo/> <FacebookNoBgLogo/> <MailNoBgLogo/></Link>
              <div style={{ clear: 'both', marginBottom: '1rem' }}></div>
            </div>
            : null
          }
        </div>
      </div>
      {(item.source_documents && item.source_documents.length)
        ? <div styleName="sources">
          {item.source_documents.map((link, idx2) => <div key={idx2} styleName="link"><Link
            to={{ link: link.url }}><HTML>{link.title}</HTML></Link></div>)}
        </div>
        : null}

    </div>
  }

  render () {
    const { searchString, lockInput, response, relevant_questions, requiredUserLevel } = this.state
    const { accessLevel } = this.props
    const hasPermission = accessLevel >= requiredUserLevel

    return <div styleName="ai-block">
      <div styleName="ai-view">
        <div styleName="header">
          <div>
            <Link to={{ link: '/tuuma' }}>
              <img src={tuuma} alt="Tuuma"/>
            </Link>
          </div>
          <div>
            <p>Haluatko tietää lisää? Kysy TM:n tekoälyltä.</p>
          </div>
        </div>
      </div>
      <div styleName="footer">
        <div styleName="input-box">
          {hasPermission
            ? <textarea id="tuuma-textarea" rows="1"
                        placeholder="Kirjoita kysymyksesi tähän."
                        readOnly={lockInput} value={searchString} onKeyUp={this.handleEnter}
                        onChange={e => this.textChange(e)}/>
            : <textarea id="tuuma-textarea" rows="1"
                        placeholder="Tilaajana voit käyttää Tuumaa"
                        readOnly={true} />
          }
          {hasPermission
            ? <button styleName="ask" id="ask" onClick={() => searchString && this.postQuestion()} disabled={lockInput}><img
              src={arrow} alt="Lähetä" styleName="no-hover"/><img
              src={arrowHover} alt="Lähetä" styleName="hover"/></button>
            : <a styleName="ask" id="ask" href={WP.getLoginUrl()}><img src={lock} alt="Kirjaudu"/></a>
          }
        </div>
      </div>
      {relevant_questions && relevant_questions.length ? <div styleName="relevant">
        <div styleName="rel-icon">
          <img src={circleGray} alt="?"/>
        </div>
        <div styleName="qs">
          {relevant_questions.map(q => <button onClick={() => this.setState({response: q})}>{q.question}</button>)}
        </div>
      </div> : null}
      {response ? this.renderAnswer(response) : null}
    </div>
  }
}

AiArticleWidget.propTypes = {
  premiumUser: PropTypes.bool,
  history: PropTypes.object,
  article: PropTypes.object
}

export default track({ gtmContext: ['AiWidget'] })(withRouter(AiArticleWidget))
